@import '../../styles/_constants.module.scss';
@import '../../styles/type/type.scss';
@import '../../styles/common.scss';

.footer {
  background: $charcoal;
  color: $white;
  padding: $phone-margin 0 $phone-margin-vert * 2.5;
  width: 100%;
  position: relative;
  z-index: 1;

  @include desktop-and-tablet {
    padding: $desktop-margin 0;
    // position: unset;
    z-index: unset;
  }
}

.container {
  @include maxWidthContainer;
  padding: 0 $phone-margin;

  @include desktop-and-tablet {
    padding: 0 $desktop-margin;
  }
}

.grid {
  display: flex;
  flex-direction: column;

  @include desktop-and-tablet {
    flex-direction: row;
  }
}

.addressContainer {
  margin: rem-property(119px) 0 rem-property(364px);
  order: 2;
  @include desktop-and-tablet {
    order: 1;
    flex-basis: calc(#{percentage(5 / 16)} - #{$desktop-margin});
    margin: 3em auto 0 0;
  }

  p {
    margin-top: 0;
  }
}

.address {
  @include abc_regular_16_130;

  a:hover {
    opacity: $hover-opacity;
  }
}

.signupContainer {
  width: 100%;
  margin: rem-property(119px) 0;
  order: 1;
  @include desktop-and-tablet {
    order: 2;
    flex-basis: calc(#{percentage(7 / 16)} - #{$desktop-margin});
    margin: rem-property(56px) 0 rem-property(56px) auto;
  }

  p {
    margin-top: 0;
  }
}

.logoContainer {
  width: rem-property(234px);
  height: rem-property(67px);
  position: relative;
  margin-bottom: rem-property(22px);

  @include desktop-and-tablet {
    width: rem-property(234px);
    height: rem-property(67px);
    margin-bottom: rem-property(22px);
  }
}

.navContainer {
  display: flex;
  flex-direction: column;

  @include desktop-and-tablet {
    align-items: flex-end;
    flex-direction: row;
  }
}
.navList {
  @include abc_mono_11_120;
  text-transform: uppercase;
  order: 2;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  @include desktop-and-tablet {
    order: unset;
    align-items: unset;
    flex-wrap: nowrap;
  }

  li {
    margin-right: 3ch;
    margin-top: 1em;
    // flex: 1;

    @include desktop-and-tablet {
      margin-top: 0;
    }

    &:nth-child(1) {
      margin-right: 1ch;
      margin-top: 0;
      // flex-basis: 20%;
      // flex-grow: 1;
      // flex-shrink: 1;
      flex-basis: 100%;

      @include desktop-and-tablet {
        flex-basis: unset;
        margin-right: 5ch;
      }
    }

    &:last-child {
      margin-right: auto;
    }

    @include desktop-and-tablet {
      margin-right: 5ch;
    }
  }

  a {
    &:hover {
      opacity: $hover-opacity;
    }
  }
}

.signupTitle {
  @include abc_regular_24_130;

  @include desktop-and-tablet {
  }
}

.signupDetail {
  @include abc_regular_16_130;

  @include desktop-and-tablet {
  }
}

.socialList {
  display: flex;
  margin-left: auto;
  order: 1;

  @include desktop-and-tablet {
    order: unset;
  }

  li {
    margin-left: calc(1ch + #{$phone-margin});
    margin-right: calc(4ch - #{$phone-margin});
  }

  a {
    display: inline-block;
    position: relative;
    width: rem-property(21px);
    height: rem-property(21px);

    @include desktop-and-tablet {
      width: rem-property(21px);
      height: rem-property(21px);
    }

    &:hover {
      opacity: $hover-opacity;
    }
  }
}
