@import './lib/mixins.scss';

//
// _constants.scss
//
// Site specific variables, mixins and generic mixins which rely on site
// specific settings.
//

// largest 32-bit integer
$overlay-zindex: 2147483647;
$disabled-scroll-zindex: $overlay-zindex - 1;

$hover-opacity: 0.6;

$default-transition-time: 700ms;

$phonePortrait: 414;
$phoneMax: 768;
$tabletPortrait: 768;
$tabletMax: 1024;
$designWidth: 1439;
$maxWidth: 2000;
$transitionTime: 700;

//
// Colors
//
$black: #000000;
$black-light: #979797;
$charcoal: #161616;
$white: #ffffff;
$grey: #BCBCBC;
$grey-light: #F6F6F8;
$grey-dark: #A6A6A6;
$grey-lighter: #F0F0F0;
$teal: #AADDDF;
$blue-dark: #272A35;
$hover-color: rgba($black, $hover-opacity);


// $white-dark: 

$selection-color: rgba(100, 100, 100, 0.5);

//
// Responsive settings.
//
// Throughout this project, mobile is used to refer to any mobile device
// including a tablet. Tablet is used specifially to refer to a tablet sized
// device (whether it's a actually a mobile device or not). Phone is used to
// refer specifically to a phone sized device.
$tablet-max: $tabletMax * 1px;
$desktop-min: $tablet-max + 1px;
$tablet-portrait: $tabletPortrait * 1px;
$tablet-landscape-min: $tablet-portrait + 1px;
$phone-max: $phoneMax * 1px;
$tablet-min: $phone-max + 1px;
$phone-portrait: $phonePortrait * 1px;
$phone-landscape-min: $phone-portrait + 1px;

$design-width: $designWidth * 1px;
$max-width: $maxWidth;
@if ($maxWidth) {
  $max-width: $max-width * 1px;
}

//
// Font sizing
//
$root-font-phone-size: 12px;
$root-font-design-size: 16px;
$root-font-base-size: $root-font-design-size;
$root-font-max-size: null;
@if ($max-width) {
  $root-font-base-size: $root-font-design-size /
    $design-width *
    min($design-width, $max-width);
  $root-font-max-size: $root-font-design-size;
}

//
// Structural
//

$base-desktop-margin: 30px;
$desktop-inner: $design-width - $base-desktop-margin * 2;

$unitless-scaling-margin: $base-desktop-margin / $design-width * 100;
$desktop-margin: rem-property($base-desktop-margin);

$tablet-margin: 25px;
$tablet-inner: $tablet-portrait - $tablet-margin * 2;

$phone-margin: 10px;
$phone-inner: $phone-portrait - $phone-margin * 2;

$phone-margin-vert: $phone-margin * 6;

$small-nav-margin: 18px;

$phone-header-height: calc(#{$phone-margin} * 2 + 33px);

$base-logo-height: 126px;
$base-logo-small-height: 56px;
$logo-height: rem-property($base-logo-height);
$logo-small-height: rem-property($base-logo-small-height);

$header-height: rem-property(
  $base-logo-height + $base-desktop-margin + $base-desktop-margin
);
$header-small-height: rem-property(
  $base-logo-small-height + $small-nav-margin + $small-nav-margin
);

$vertical-margin-phone: calc(#{$phone-margin} * 5);
$vertical-margin-large: calc(#{$desktop-margin} * 3);

$h2-margin: rem-property(19px);
$h2-margin-phone: 1rem;

:export {
  black: $black;
  blackLight: rgba($black, 0.9);
  white: $white;
  teal: $teal;
  tealOpacity: rgba($teal, $hover-opacity);
  greyDark: $grey-dark;
  greyLight: $grey-light;
  greyLighter: $grey-lighter;
  grey: $grey;
  greyOpacity: rgba($grey, 0.25);
  tabletMax: $tablet-max;
  desktopMin: $desktop-min;
  tabletPortrait: $tablet-portrait;
  tabletLandscapeMin: $tablet-landscape-min;
  phoneMax: $phone-max;
  tabletMin: $tablet-min;
  phonePortrait: $phone-portrait;
  phoneLandscapeMin: $phone-landscape-min;
  designWidth: $design-width;
  maxWidth: $max-width;

  desktopMargin: $desktop-margin;
  desktopInner: $desktop-inner;
  tabletMargin: $tablet-margin;
  tabletInner: $tablet-inner;
  phoneMargin: $phone-margin;
  phoneInner: $phone-inner;
  phoneHeaderHeight: $phone-header-height;
  headerHeight: $header-height;
  headerSmallHeight: $header-small-height;
  verticalMarginPhone: $vertical-margin-phone;
  verticalMarginLarge: $vertical-margin-large;
  rootFontBaseSize: $root-font-base-size;
  
  transitionTime: $transitionTime;

}

// These mixins provide convenience methods for styles that should be applied
// at certain viewport sizes.

@mixin above-max-width {
  @media screen and (min-width: #{$max-width + 1}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin desktop-and-tablet-landscape {
  @media screen and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin desktop-and-tablet {
  @media screen and (min-width: $tablet-min) {
    @content;
  }
}

@mixin desktop-tablet-and-phone-landscape {
  @media screen and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-and-phone {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: $tablet-portrait) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone-landscape {
  @media screen and (max-width: $tablet-portrait) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone {
  @media screen and (max-width: $tablet-portrait) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $phone-max) {
    @content;
  }
}

@mixin phone-landscape {
  @media screen and (max-width: $phone-max) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin phone-portrait {
  @media screen and (max-width: $phone-portrait) {
    @content;
  }
}

// Mixins for behaviour based on device pointer type

@mixin mouse-device {
  @media (hover: hover) {
    @content;
  }

  @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE11 doesn't support @media(hover), so it just gets the mouse behaviour
    @content;
  }
}

@mixin touch-device {
  @media (hover: none) {
    @content;
  }
}

//
// Anchor styles
//

@mixin clear-anchor-style {
  background-image: none;
  text-decoration: none;

  @include hover {
    background-image: none;
  }

  &.current-page {
    background-image: none;

    &.related-hovered {
      background-image: none;
    }
  }
}

// By default:
// 1. Links are underlined, except for links to the current-page (see
//    anchors.js);
// 2. The underline is removed on hover;
// 3. Links to the current-page are underlined if a related link (see
//    anchors.js) is hovered.
@mixin default-anchor-style($color: currentColor, $weight: 1px, $offset: 0) {
  @include underline($color: $color, $weight: $weight, $offset: $offset);
  text-decoration: none;

  @include hover {
    background-image: none;
  }

  &.current-page {
    background-image: none;

    &.related-hovered {
      @include underline($color: $color, $weight: $weight, $offset: $offset);
    }
  }
}

// The inverse style, typically used for headers and navs:
// 1. Links are not underlined, except for links to the current-page (see
//    anchors.js);
// 2. An underline is added on hover;
// 3. The underline on the link to the current-page is removed if a related
//    link (see anchors.js) is hovered.
@mixin inverse-anchor-style($color: currentColor, $weight: 1px, $offset: 0) {
  background-image: none;
  text-decoration: none;

  @include hover {
    @include underline($color: $color, $weight: $weight, $offset: $offset);
  }

  &.current-page {
    @include underline($color: $color, $weight: $weight, $offset: $offset);

    &.related-hovered {
      background-image: none;
    }
  }
}

@mixin opacity-anchor-style {
  @include hover {
    opacity: $hover-opacity;
  }

  &.current-page {
    opacity: $hover-opacity;

    &.related-hovered {
      opacity: 1;
    }
  }
}

@mixin color-anchor-style($color) {
  @include hover {
    color: $color;
  }

  &.current-page {
    color: $color;

    &.related-hovered {
      color: inherit;
    }
  }
}
