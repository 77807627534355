@import './_constants.module.scss';
@import './fonts.scss';

html {
  // overflow-y: scroll;  // always show vertical scrollbar on window
  @include root-font;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  min-height: 100%;
  // position: relative;
  margin: 0;
}

#wrap {
  position: relative;

  @include desktop-and-tablet {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    align-items: flex-start;
  }
}

#content {
  @include clearfix;
  width: 100%;
  min-height: 100vh;
  opacity: 1;
  @include desktop-and-tablet {
    position: relative;
    z-index: 0;
  }
}

// .header,
.footer {
  opacity: 1;
  @include desktop-and-tablet {
    position: relative;
    z-index: 0;
  }
}

.structural {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // assign left and right in case we fix one of these elements.
  left: 0;
  right: 0;

  @if ($max-width) {
    max-width: $max-width;
  }
}

ul {
  padding-left: 0;

  li {
    list-style: none;
    margin-left: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

figure {
  margin: 0;
}

::selection {
  background: $selection-color;
  color: inherit;
  text-shadow: none;
}

#content .swiper-button-prev {
  background-image: url('/slider-arrow-left.svg');
  width: rem-property(47.9px);
  height: rem-property(46px);
  background-size: contain;
  background-repeat: no-repeat;

  &:after {
    display: none;
  }
}

#content .swiper-button-next {
  background-image: url('/slider-arrow-right.svg');
  width: rem-property(47.9px);
  height: rem-property(46px);
  background-size: contain;
  background-repeat: no-repeat;

  &:after {
    display: none;
  }
}

#content .news-button .swiper-button-next {
  top: 120px;
}
#content .news-button .swiper-button-prev {
  display: none;
}

#content .news-button--low .swiper-button-next {
  top: 150px;
}
#content .news-button--low .swiper-button-prev {
  display: none;
}

#content .swiper-slide {
  cursor: grab;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  width: calc(90% - $phone-margin * 2);
  @include desktop-and-tablet {
    width: rem-property(500px);
  }
}

#content .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

.superscript {
  font-size: 0.4em;
  vertical-align: top;
}
