@import '../constants.module.scss';
@import '../lib/_mixins.scss';
@import '../lib/_type_mixins.scss';

//
// _type.scss
//

//
// Font mixins
//

@mixin example-regular($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'example-regular', 'helvetica', sans-serif;
  font-size: $rem-size;
  font-weight: 400;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

@mixin abc-regular($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'ABC Diatype', 'helvetica', sans-serif;
  font-size: $rem-size;
  font-weight: 400;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

@mixin abc-medium($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'ABC Diatype', 'helvetica', sans-serif;
  font-size: $rem-size;
  font-weight: 500;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

@mixin abc-bold($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'ABC Diatype', 'helvetica', sans-serif;
  font-size: $rem-size;
  font-weight: 700;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

@mixin abc-mono-light($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'ABC Diatype Semi-mono', monospace;
  font-size: $rem-size;
  font-weight: 300;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

@mixin abc-mono-regular($font-px-size: $root-font-base-size, $min-px-size: 0) {
  $rem-size: rem-property($font-px-size);
  font-family: 'ABC Diatype Semi-mono', monospace;
  font-size: $rem-size;
  font-weight: 400;

  // This is not supported in older browsers but support based on global usage
  // is around 90% and is probably significantly higher on the devices this is
  // relevant for.
  @if $min-px-size > 0 {
    // This weird #{a} is necessary because scss has a built in max function
    // which causes issues
    font-size: m#{a}x(#{$rem-size}, #{$min-px-size});
  }
}

// You can override these by setting them in constants, or change them here,
// but font sizes smaller than 16px may result in mobile device browsers
// zooming in when an input is focussed.
$input-max-px-size: 16px !default;
$input-min-px-size: 16px !default;

@mixin input-type-sizing {
  @if $input-max-px-size == $input-min-px-size {
    font-size: $input-max-px-size;
  } @else {
    $rem-size: rem-property($input-max-px-size);
    font-size: $rem-size;

    @if $input-min-px-size > 0 {
      // This weird #{a} is necessary because scss has a built in max function
      // which causes issues
      font-size: m#{a}x(#{$rem-size}, #{$input-min-px-size});
    }
  }
}

//
// Body type sizing and settings.
//
// Frequently Tim wants:
// 1. Text to resize with viewport (subject to point 3)
// 2. Text to remain in its original proportion to text around it (ie. scale
//    consistently)
// 3. The font to always break to a new line at the same point
// 4. At some, point the font to stop growing but any containing element
//    to continue growing
//
// Therefore, typically we (matching numbering above):
// 1. Set a vw based font size on the html element
// 2. Adjust font-size using rem
// 3. Set the width of the element containing the text using the
//    em-property function
// 4. Put an upper limit on the body's vw based font-size, and size ancestors
//    of the element containing the text using %.
//
@mixin root-font {
  @include font-settings;
  @include vw-font-size(
    $root-font-base-size,
    $min-px-size: $root-font-phone-size,
    $max-px-size: $root-font-max-size,
    $as-at: $design-width
  );
  font-family: 'ABC Diatype', 'helvetica', sans-serif;
}

// Site specific font mixins.
//
// These mixins define reusable groups of font-related styles, and should be
// used where possible to keep type styles consistent around the site.

// Style to be applied to type editable as html in the admin.
@mixin common-editor-styles {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  blockquote:first-child {
    > :first-child {
      margin-top: 0;
    }
  }

  blockquote:last-child {
    > :last-child {
      margin-bottom: 0;
    }
  }

  h3,
  h4,
  h5,
  h6,
  li {
    font-weight: normal;

    a {
      @include inverse-anchor-style;
    }
  }

  p,
  blockquote {
    a {
      @include default-anchor-style;
    }
  }
}

@mixin default-editor-styles {
}

@mixin abc_bold_65_110() {
  @include abc-bold(30px, 30px);
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-bold(65px);
  }
}

@mixin abc_bold_30_110() {
  @include abc-bold(13.8px, 13.8px);
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-bold(30px);
  }
}

@mixin abc_bold_phone_30() {
  @include abc-bold(30px, 30px);
  line-height: 1.1;
  letter-spacing: -0.02em;
}

@mixin abc_regular_30_110() {
  @include abc-regular(16px, 16px);
  line-height: 1.2;
  letter-spacing: -0.025em;

  @include desktop-and-tablet {
    @include abc-regular(30px);
    line-height: 1.1;
  }
}

@mixin abc_regular_30_105() {
  @include abc-regular(16px, 16px);
  line-height: 1.05;
  letter-spacing: -0.02em;

  @include desktop-and-tablet {
    @include abc-regular(30px);
  }
}

@mixin abc_regular_34_110() {
  @include abc-regular(30px, 30px);
  line-height: 1.1;
  letter-spacing: -0.025em;

  @include desktop-and-tablet {
    @include abc-regular(34px);
  }
}

@mixin abc_regular_24_130() {
  @include abc-regular(16px, 16px);
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-regular(24px);
  }
}

@mixin abc_regular_22_120() {
  @include abc-regular(16px, 16px);
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-regular(22px);
  }
}

@mixin abc_regular_20_110() {
  @include abc-regular(12px, 12px);
  line-height: 1.1;
  letter-spacing: -0.025em;

  @include desktop-and-tablet {
    @include abc-regular(20px);
  }
}

@mixin abc_regular_phone_20_130() {
  @include abc-regular(20px, 20px);
  line-height: 1.3;
  letter-spacing: 0;
}

@mixin abc_regular_phone_16_130 {
  @include abc-regular(16px, 16px);
  line-height: 1.3;
  letter-spacing: 0;
}

@mixin abc_regular_phone_15_130 {
  @include abc-regular(15px, 15px);
  line-height: 1.3;
  letter-spacing: 0;
}

@mixin abc_regular_phone_14_130 {
  @include abc-regular(14px, 14px);
  line-height: 1.3;
  letter-spacing: 0;
}

@mixin abc_medium_18_110() {
  @include abc-medium(15px, 15px);
  line-height: 1.1;
  letter-spacing: 0;

  @include desktop-and-tablet {
    @include abc-medium(18px);
  }
}

@mixin abc_medium_phone_20_130() {
  @include abc-medium(20px, 20px);
  line-height: 1.3;
  letter-spacing: 0;
}

@mixin abc_regular_16_130() {
  @include abc-regular(14px, 14px);
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-regular(16px);
  }
}

@mixin abc_regular_18_130() {
  @include abc-regular(17px, 17px);
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-regular(18px);
  }
}

@mixin abc_mono_15_120() {
  @include abc-mono-regular(13px, 13px);
  line-height: 1.2;
  letter-spacing: -0.02em;

  @include desktop-and-tablet {
    @include abc-mono-regular(15px);
  }
}

@mixin abc_mono_11_120() {
  @include abc-mono-regular(10px, 10px);
  line-height: 1.2;
  letter-spacing: -0.02em;

  @include desktop-and-tablet {
    @include abc-mono-regular(11px);
  }
}

@mixin abc_mono_20_120() {
  @include abc-mono-regular(15px, 15px);
  line-height: 1.2;
  letter-spacing: 0;
  text-transform: uppercase;

  @include desktop-and-tablet {
    @include abc-mono-regular(20px);
  }
}

@mixin abc_regular_65_110() {
  @include abc-regular(30px, 30px);
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-regular(65px);
  }
}

@mixin abc_mono_13_120() {
  @include abc-mono-regular(13px, 13px);
  line-height: 1.2;
  letter-spacing: 0;

  @include desktop-and-tablet {
    @include abc-mono-regular(13px);
  }
}

@mixin abc_mono_14_120() {
  @include abc-mono-regular(13px, 13px);
  line-height: 1.2;
  letter-spacing: 0;

  @include desktop-and-tablet {
    @include abc-mono-regular(14.5px);
  }
}

@mixin abc_medium_65_110() {
  @include abc-medium(30px, 30px);
  line-height: 1.1;
  letter-spacing: -0.02em;

  @include desktop-and-tablet {
    @include abc-medium(65px);
  }
}

@mixin abc_mono_22_120() {
  @include abc-mono-light(10.2px, 10.2px);
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include desktop-and-tablet {
    @include abc-mono-light(22px);
  }
}

@mixin abc_bold_140_89() {
  @include abc-bold(60px, 60px);
  line-height: 0.89;
  letter-spacing: -0.04em;

  @include desktop-and-tablet {
    @include abc-bold(105px);
  }
}

@mixin abc_bold_140_89_md() {
  @include abc-bold(60px, 60px);
  line-height: 0.89;
  letter-spacing: -0.04em;

  @include tablet {
    @include abc-bold(75px);
  }

  @include desktop {
    @include abc-bold(90px);
  }

  @include above-max-width {
    @include abc-bold(105px);
  }
}
