@mixin font-settings {
    // sass-lint:disable no-duplicate-properties
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'kern';
    -moz-font-feature-settings: 'kern';
    -moz-font-feature-settings: 'kern=1';
    -webkit-font-kerning: normal;
    -moz-font-kerning: normal;
    font-feature-settings: 'kern';
    font-kerning: normal;
  }
  
  @mixin vw-font-size(
    $px-size,
    $min-px-size: null,
    $max-px-size: null,
    $as-at: $design-width
  ) {
    $unitless-vw-size: $px-size / $as-at * 100;
    $stop-shrinking-at: if(
      $min-px-size,
      round($as-at * $min-px-size / $px-size),
      null
    );
    $start-shrinking-at: if(
      $max-px-size,
      round($as-at * $max-px-size / $px-size),
      null
    );
  
    @if $stop-shrinking-at {
      @media (max-width: $stop-shrinking-at) {
        font-size: $stop-shrinking-at * $unitless-vw-size / 100;
      }
  
      @if $start-shrinking-at {
        @media (min-width: #{$stop-shrinking-at + 1px}) and
                 (max-width: #{$start-shrinking-at - 1px}) {
          font-size: #{$unitless-vw-size + 'vw'};
        }
  
        @media (min-width: $start-shrinking-at) {
          font-size: $start-shrinking-at * $unitless-vw-size / 100;
        }
      } @else {
        @media (min-width: #{$stop-shrinking-at + 1}) {
          font-size: #{$unitless-vw-size + 'vw'};
        }
      }
    } @else if $start-shrinking-at {
      @media (max-width: #{$start-shrinking-at - 1}) {
        font-size: #{$unitless-vw-size + 'vw'};
      }
  
      @media (min-width: $start-shrinking-at) {
        font-size: $start-shrinking-at * $unitless-vw-size / 100;
      }
    } @else {
      font-size: $px-size;
    }
  }
  