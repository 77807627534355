@import '../../styles/_constants.module.scss';
@import '../../styles/type/type.scss';
@import '../../styles/common.scss';

$nav-margin: rem-property(24px);
$nav-margin-dark: rem-property($small-nav-margin);

.navContainer {
  transition: color 100ms;
  // opacity: 1;
}

// .navContainerOpacity {
//   opacity: 0;
// }

.container {
  @include maxWidthContainer;
  display: flex;
  justify-content: space-between;

  @include desktop-and-tablet {
  }
}

.navContainerLight {
  composes: navContainer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(#{rem-property(94px)} + #{$nav-margin} * 2);
  z-index: 1;
  // opacity: 0;

  @include desktop-and-tablet {
    height: calc(#{rem-property(94px)} + #{$nav-margin} * 2);
    color: $white;
  }
}

.navContainerDark {
  composes: navContainer;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: $white;
  color: $black;
  height: $header-small-height;
  width: 100%;
  // transform: translateY(-103%);
  // transition: transform 400ms;

  @include desktop-and-tablet {
  }
}

.logo {
  @include desktop-and-tablet {
  }
}

.logoContainer {
  width: 100%;
  padding-top: $nav-margin-dark;
  padding-bottom: $nav-margin-dark;
  padding-left: $phone-margin;
  position: relative;
  z-index: 10;

  @include desktop-and-tablet {
    width: rem-property(331px);
    height: rem-property(94px);
    margin-right: auto;
    margin-top: $nav-margin;
    margin-bottom: $nav-margin;
    margin-left: $desktop-margin;
    position: relative;
    padding: 0;
  }

  .navContainerDark & {
    background: $white;
    margin: 0;

    @include desktop-and-tablet {
      width: rem-property(199px);
      height: rem-property(56px);
      margin: $nav-margin / 2 0 $nav-margin / 2 $desktop-margin;
    }

    a {
      display: block;
      position: relative;
      width: rem-property(199px);
      height: rem-property(56px);
    }
  }

  a {
    display: block;
    position: relative;
    width: 203px;
    height: 58px;

    @include desktop-and-tablet {
      width: 100%;
      height: 100%;
    }
  }
}

.hamburgerContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
}

.hamburger {
  @include hamburger(22px, 30px, 100%, 2px, 5px);
  color: $white;

  @include desktop-and-tablet {
    display: none;
  }
  .navContainerDark & {
    color: $black;
  }

  &.active {
    color: $black;
  }
}

.navBorder {
  display: none;

  @include desktop-and-tablet {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 7px;
    background: $black;
    z-index: 1;
    opacity: 0;
  }
}

.navListContainer {
  display: none;

  @include desktop-and-tablet {
    display: flex;
    height: $header-small-height;
    position: relative;
  }

  &.navListContainerActive {
    display: block;

    position: fixed;
    background: $white;
    top: $header-small-height;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$header-small-height});
    z-index: 1;
    overflow-y: scroll;

    @include desktop-and-tablet {
      display: none;
    }
  }
}

.navList {
  margin: 54px $phone-margin 108px;

  @include desktop-and-tablet {
    display: flex;
    margin: 0 $desktop-margin 0;
  }
}

.navItem {
  @include abc_medium_65_110;
  font-size: 45px;
  line-height: 1.1;
  text-transform: capitalize;
  border-top: 1px solid $black;
  padding: 0.15em 0;

  @include desktop-and-tablet {
    @include abc_mono_14_120;
    line-height: 1.4;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 0 rem-property(25px);
    border-top: none;
    height: 100%;
  }

  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include hover {
    @include desktop-and-tablet {
      opacity: 0.6;
    }
  }

  &:first-child {
    border-top: none;
  }
}

.navItemActive {
  composes: navItem;
  color: $teal;

  @include desktop-and-tablet {
    color: $black;
  }
}

.linkInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
