@import '../../styles/_constants.module.scss';
@import '../../styles/type/type.scss';
@import '../../styles/common.scss';

.form {
  @include abc_regular_24_130;
}

.errors {
  color: red;
  margin: 0.5em 0;
}

.emailField {
  display: flex;
  padding-bottom: 0.2em;
  border-bottom: rem-property(1px) solid rgba($white, $hover-opacity);
  margin-top: 2em;
  input {
    @include abc_regular_24_130;
    background: none;
    color: $white;
    border: none;
    outline: none;
    flex-basis: calc(100% - #{rem-property(16px)});
    padding: 0;
    // opacity: $hover-opacity;

    // &:active,
    // &:focus {
    //   opacity: 1;
    // }

    &::placeholder {
      opacity: $hover-opacity;
      color: $white;
      padding: 0;
    }
  }
}

.linkArrow {
  width: rem-property(16px);
  height: rem-property(13px);
  display: inline-block;
  position: relative;
  margin-right: 1ch;
}

.button {
  background: none;
  color: $white;
  border: none;
  outline: none;
  cursor: pointer;

  @include hover {
    opacity: $hover-opacity;
  }
}

.formSubmitted {
  min-height: 3em;
  margin-top: 2em;
}
