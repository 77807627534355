@import './type/type.scss';

// font-face declarations are inlined so that the font files start loading
// asap

@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  @font-face {
    font-family: '#{$name}';
    src: url('../fonts/' + $filename + '.woff2') format('woff2'),
      url('../fonts/' + $filename + '.woff') format('woff'),
      url('../fonts/' + $filename + '.otf') format('otf');
    font-weight: $weight;
    font-style: $style;
  }
}

@include webfont('ABC Diatype', 'ABCDiatype-Regular', 400);
@include webfont('ABC Diatype', 'ABCDiatype-Medium', 500);
@include webfont('ABC Diatype', 'ABCDiatype-Bold', 700);

@include webfont('ABC Diatype Semi-mono', 'ABCDiatypeSemi-Mono-Regular', 400);
@include webfont('ABC Diatype Semi-mono', 'ABCDiatypeSemi-Mono-Light', 300);
// @include webfont('SpaceGrotesk', 'SpaceGrotesk-Medium', 400);
// @include webfont('FKGroteskSemiMono', 'FKGroteskSemiMono-Medium', 400);
